// components/Feedback.js
import React from 'react';

const Feedback = () => {
  return (
    <div className="component-container">
      <h2 className="component-title">Feedback</h2>
      <p className="component-text">Share your thoughts with us!</p>
    </div>
  );
};

export default Feedback;
