import React, { useState, useEffect, useCallback } from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import logo from './el_barrio_logo.png';
import './index.css';

// Import components
import Home from './components/Home';
import Order from './components/Order';
import MyPoints from './components/MyPoints';
import Profile from './components/Profile';
import Feedback from './components/Feedback';
import ShareAndEarn from './components/ShareAndEarn';
import AnimatedHamburger from './components/AnimatedHamburger';

function App() {
  const [isNavOpen, setIsNavOpen] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [customer, setCustomer] = useState(null);

  useEffect(() => {
    const storedCustomer = localStorage.getItem('customer') || sessionStorage.getItem('customer');
    if (storedCustomer) {
      const customerData = JSON.parse(storedCustomer);
      setCustomer(customerData);
      setIsAuthenticated(true);
    }
  }, []);

  const handleLogin = useCallback((customerData) => {
    setCustomer(customerData);
    setIsAuthenticated(true);
    localStorage.setItem('customer', JSON.stringify(customerData));
  }, []);

  const handleLogout = useCallback(() => {
    setCustomer(null);
    setIsAuthenticated(false);
    localStorage.removeItem('customer');
    sessionStorage.removeItem('customer');
  }, []);

  return (
    <Router>
      <div className="min-h-screen bg-gray-900 text-white">
        <header className="bg-gray-900 shadow-lg">
          <div className="container mx-auto px-4 py-4 flex justify-between items-center">
            <div className="flex items-center">
              <img src={logo} alt="El Barrio logo" className="h-12 w-auto mr-4" />
              <span className="text-2xl font-bold">Barrio Bucks</span>
            </div>
            <nav className="hidden md:flex space-x-6">
              <Link to="/" className="hover:text-blue-400">Home</Link>
              <Link to="/order" className="hover:text-blue-400">Order</Link>
              {isAuthenticated && (
                <>
                  <Link to="/points" className="hover:text-blue-400">My Points</Link>
                  <Link to="/profile" className="hover:text-blue-400">Profile</Link>
                </>
              )}
              <Link to="/feedback" className="hover:text-blue-400">Feedback</Link>
              <Link to="/share" className="hover:text-blue-400">Share & Earn</Link>
              {isAuthenticated && (
                <button onClick={handleLogout} className="hover:text-blue-400">Logout</button>
              )}
            </nav>
            <div className="md:hidden">
              <AnimatedHamburger isOpen={isNavOpen} toggle={() => setIsNavOpen(!isNavOpen)} />
            </div>
          </div>
        </header>

        {isNavOpen && (
          <nav className="md:hidden bg-gray-800 p-4">
            <Link to="/" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Home</Link>
            <Link to="/order" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Order</Link>
            {isAuthenticated && (
              <>
                <Link to="/points" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>My Points</Link>
                <Link to="/profile" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Profile</Link>
              </>
            )}
            <Link to="/feedback" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Feedback</Link>
            <Link to="/share" className="block py-2 hover:text-blue-400" onClick={() => setIsNavOpen(false)}>Share & Earn</Link>
            {isAuthenticated && (
              <button onClick={handleLogout} className="block py-2 hover:text-blue-400">Logout</button>
            )}
          </nav>
        )}

        <main className="container mx-auto px-4 py-8">
          <Routes>
            <Route path="/" element={<Home onLogin={handleLogin} />} />
            <Route path="/order" element={<Order />} />
            {isAuthenticated && (
              <>
                <Route path="/points" element={<MyPoints customer={customer} />} />
                <Route path="/profile" element={<Profile customer={customer} onUpdateCustomer={setCustomer} onLogout={handleLogout} />} />
              </>
            )}
            <Route path="/feedback" element={<Feedback />} />
            <Route path="/share" element={<ShareAndEarn />} />
          </Routes>
        </main>
      </div>
    </Router>
  );
}

export default App;