import React, { useState, useEffect, useRef, useCallback } from 'react';
import axios from 'axios';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements, PaymentRequestButtonElement } from '@stripe/react-stripe-js';
import BarrioBucksBalanceAnimation from './BarrioBucksBalanceAnimation';

const API_URL = 'https://barriobucks.com/api';

const stripePromise = loadStripe('pk_test_51NRY6oC5fulBWHCX8FhE5QzsANqGHSPt5ReBznDq27JwFKUvUSKsUsZtQvw6T35RpwRHI0yXieLF9oBIxeUgZkzA00XBKBH7Gv', {
  apiVersion: '2023-08-16',
  stripeAccount: 'acct_1NRY6oC5fulBWHCX',
});

const formatPhoneNumber = (input) => {
  const digits = input.replace(/\D/g, '');
  return digits.length === 10 ? digits.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3') : digits;
};

const PaymentForm = ({ customer, onPaymentSuccess }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [amount, setAmount] = useState('');
  const amountRef = useRef('');
  const [paymentRequest, setPaymentRequest] = useState(null);
  const [error, setError] = useState('');
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const [initialBalance, setInitialBalance] = useState(customer.barrio_bucks);
  const [finalBalance, setFinalBalance] = useState(customer.barrio_bucks);
  const [showAnimation, setShowAnimation] = useState(false);

  const handleAmountChange = (e) => {
    const value = e.target.value;
    if (value === '' || /^\d*\.?\d{0,2}$/.test(value)) {
      setAmount(value);
      amountRef.current = value;
      if (paymentRequest) {
        paymentRequest.update({
          total: {
            label: 'Barrio Bucks Payment',
            amount: Math.round(parseFloat(value || '0') * 100),
          },
        });
      }
    }
  };

  const createPaymentIntent = async (amount) => {
    if (isNaN(amount) || amount <= 0) {
      return { error: { message: 'Invalid amount' } };
    }
    const payload = { amount: Math.round(amount * 100) };
    try {
      const response = await axios.post(`${API_URL}/create-payment-intent`, payload);
      return { clientSecret: response.data.clientSecret };
    } catch (err) {
      return { error: { message: err.response ? err.response.data.error : err.message } };
    }
  };

  const handlePaymentSuccess = useCallback(async (amount, paymentIntentId) => {
    try {
      const amountInCents = Math.round(parseFloat(amount) * 100);
      const response = await axios.post(`${API_URL}/payment-success`, {
        phone: customer.phone,
        amount: amountInCents,
        customerId: customer.id,
        paymentIntentId: paymentIntentId
      });
      setPaymentSuccess(true);
      const newBalance = response.data.customer.barrio_bucks;
      setInitialBalance(customer.barrio_bucks);
      setFinalBalance(newBalance);
      setShowAnimation(true);
      onPaymentSuccess(newBalance, amountInCents / 100);
      setError('');
    } catch (err) {
      console.error('Error sending payment success to server:', err);
      setError('Payment processed, but there was an error updating your account. Please contact support.');
    }
  }, [customer, onPaymentSuccess]);

  useEffect(() => {
    if (stripe) {
      const pr = stripe.paymentRequest({
        country: 'US',
        currency: 'usd',
        total: {
          label: 'Barrio Bucks Payment',
          amount: 100,
        },
        requestPayerName: true,
        requestPayerEmail: true,
      });

      pr.canMakePayment().then(result => {
        if (result) {
          setPaymentRequest(pr);
        }
      });

      pr.on('paymentmethod', async (ev) => {
        const currentAmount = parseFloat(amountRef.current);
        if (isNaN(currentAmount) || currentAmount <= 0) {
          ev.complete('fail');
          setError('Invalid amount');
          return;
        }
        const { error: backendError, clientSecret } = await createPaymentIntent(currentAmount);
      
        if (backendError) {
          ev.complete('fail');
          setError(backendError.message);
          return;
        }
    
        const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(
          clientSecret,
          { payment_method: ev.paymentMethod.id },
          { handleActions: false }
        );
    
        if (stripeError) {
          ev.complete('fail');
          setError(stripeError.message);
        } else {
          ev.complete('success');
          if (paymentIntent.status === 'succeeded') {
            await handlePaymentSuccess(currentAmount, paymentIntent.id);
          }
        }
      });

      return () => {
        pr.off('paymentmethod');
      };
    }
  }, [stripe, handlePaymentSuccess]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const amountInCents = Math.round(parseFloat(amountRef.current) * 100);
  
    if (isNaN(amountInCents) || amountInCents <= 0) {
      setError('Invalid amount. Please enter a valid number.');
      return;
    }
    if (!stripe || !elements || !amount) {
      return;
    }
  
    try {
      const result = await createPaymentIntent(parseFloat(amount));
  
      if (result.error) {
        setError(result.error.message);
        return;
      }
  
      const { error: stripeError, paymentIntent } = await stripe.confirmCardPayment(result.clientSecret, {
        payment_method: {
          card: elements.getElement(CardElement),
        },
      });
  
      if (stripeError) {
        setError(stripeError.message);
      } else if (paymentIntent.status === 'succeeded') {
        await handlePaymentSuccess(amount, paymentIntent.id);
      }
    } catch (err) {
      setError('An unexpected error occurred. Please try again.');
    }
  };
  
  return (
    <div>
      {!paymentSuccess ? (
        <form onSubmit={handleSubmit} className="space-y-4">
          <div>
            <label htmlFor="amount" className="block text-sm font-medium text-gray-700">Amount ($)</label>
            <input
              id="amount"
              type="number"
              value={amount}
              onChange={handleAmountChange}
              placeholder="Enter amount"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
            />
          </div>
          {paymentRequest && (
            <PaymentRequestButtonElement
              options={{ paymentRequest }}
              className="w-full"
            />
          )}
          <div>
            <label htmlFor="card-element" className="block text-sm font-medium text-gray-700">Credit or debit card</label>
            <div className="w-full overflow-hidden">
              <CardElement
                id="card-element"
                options={{
                  style: {
                    base: {
                      fontSize: '16px',
                      color: '#424770',
                      '::placeholder': {
                        color: '#aab7c4',
                      },
                    },
                    invalid: {
                      color: '#9e2146',
                    },
                  },
                }}
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
              />
            </div>
          </div>
          <button
            type="submit"
            disabled={!stripe || !amount}
            className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-300 disabled:cursor-not-allowed"
          >
            Pay ${amount || '0'}
          </button>
        </form>
      ) : (
        <div className="mt-4 p-4 bg-green-100 border border-green-400 text-green-700 rounded">
          <h2 className="text-2xl font-semibold mb-2">Thanks, {customer.name}!</h2>
          <p className="font-semibold mb-2">Payment successful! Thank you for your purchase.</p>
          <p className="text-lg font-semibold">Your new Barrio Bucks Balance:</p>
          {showAnimation && (
            <BarrioBucksBalanceAnimation 
              initialBalance={initialBalance}
              finalBalance={finalBalance}
            />
          )}
          <p className="mt-2">You just added ${parseFloat(amount).toFixed(2)}!</p>
        </div>
      )}
      {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
    </div>
  );
};

const Home = ({ onLogin }) => {
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [isNewCustomer, setIsNewCustomer] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState('');
  const [customer, setCustomer] = useState(null);
  const [keepLoggedIn, setKeepLoggedIn] = useState(false);

  useEffect(() => {
    const storedCustomer = localStorage.getItem('customer') || sessionStorage.getItem('customer');
    if (storedCustomer) {
      const parsedCustomer = JSON.parse(storedCustomer);
      setCustomer(parsedCustomer);
      onLogin(parsedCustomer);
    }
  }, [onLogin]);

  const handleLogin = useCallback((customerData) => {
    setCustomer(customerData);
    onLogin(customerData);
    if (keepLoggedIn) {
      localStorage.setItem('customer', JSON.stringify(customerData));
    } else {
      sessionStorage.setItem('customer', JSON.stringify(customerData));
    }
  }, [keepLoggedIn, onLogin]);

  const handlePhoneChange = (e) => {
    setPhoneNumber(formatPhoneNumber(e.target.value));
  };

  const handleSendCode = async () => {
    try {
      await axios.post(`${API_URL}/auth/initiate`, { phone: phoneNumber });
      setIsCodeSent(true);
      setError('');
    } catch (err) {
      setError('Failed to send verification code. Please try again.');
    }
  };

  const handleVerifyCode = async (code = verificationCode) => {
    try {
      const response = await axios.post(`${API_URL}/auth/verify`, { phone: phoneNumber, code: code });
      if (response.data.newCustomer) {
        setIsNewCustomer(true);
        setError('');
      } else {
        const customerData = {
          ...response.data.customer,
          phone: phoneNumber
        };
        handleLogin(customerData);
        setError('');
      }
    } catch (err) {
      setError('Invalid verification code. Please try again.');
    }
  };

  const handleVerificationCodeChange = (e, index) => {
    const value = e.target.value;
    if (value.length <= 1) {
      const newCode = verificationCode.slice(0, index) + value + verificationCode.slice(index + 1);
      setVerificationCode(newCode);
      
      if (value && index < 5) {
        const nextInput = document.getElementById(`code-${index + 1}`);
        if (nextInput) {
          nextInput.focus();
        }
      }
      if (newCode.length === 6) {
        handleVerifyCode(newCode);
      }
    }
  };

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${API_URL}/auth/register`, {
        phone: phoneNumber,
        firstName: firstName,
        lastName: lastName
      });
      const newCustomer = {
        ...response.data.customer,
        phone: phoneNumber
      };
      handleLogin(newCustomer);
      setError('');
    } catch (err) {
      setError('Failed to register. Please try again.');
    }
  };

  if (customer) {
    return (
      <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
        <div className="p-8">
          <h2 className="text-2xl font-semibold text-gray-800">Welcome, {customer.name || 'Valued Customer'}!</h2>
          <p className="mt-2 text-gray-600">Your Barrio Bucks balance: ${customer.barrio_bucks.toFixed(2)}</p>
          <div className="mt-6">
            <Elements stripe={stripePromise}>
              <PaymentForm 
                customer={{...customer, phone: phoneNumber}} 
                onPaymentSuccess={(newBalance, amount) => {
                  setCustomer(prevCustomer => ({
                    ...prevCustomer,
                    barrio_bucks: newBalance,
                    lastPurchaseAmount: amount
                  }));
                }} 
              />
            </Elements>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl">
      <div className="p-8">
        <h2 className="text-2xl font-semibold text-gray-800">Welcome to Barrio Bucks</h2>
   
        {!isCodeSent ? (
          <div className="mt-6">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700">Enter your phone number to log in or sign up:</label>
            <input
              id="phone"
              type="tel"
              value={phoneNumber}
              onChange={handlePhoneChange}
              placeholder="(###) ###-####"
              className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
            />
            <div className="mt-2">
              <input
                id="keepLoggedIn"
                type="checkbox"
                checked={keepLoggedIn}
                onChange={(e) => setKeepLoggedIn(e.target.checked)}
                className="mr-2"
              />
              <label htmlFor="keepLoggedIn" className="text-sm text-gray-700">Keep me logged in on this device</label>
            </div>
            <button 
              onClick={handleSendCode} 
              disabled={phoneNumber.length !== 14}
              className="mt-3 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 disabled:bg-gray-300 disabled:cursor-not-allowed"
            >
              Send Verification Code
            </button>
          </div>
        ) : isNewCustomer ? (
          <div className="mt-6 space-y-4">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-700">First Name</label>
              <input
                id="firstName"
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="First Name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-700">Last Name</label>
              <input
                id="lastName"
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Last Name"
                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm text-black"
              />
            </div>
            <button 
              onClick={handleRegister}
              className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Complete Registration
            </button>
          </div>
        ) : (
          <div className="mt-6">
            <label htmlFor="verificationCode" className="block text-sm font-medium text-gray-700">Enter the 6-digit verification code sent to your phone:</label>
            <div className="flex mt-1 space-x-2">
              {[...Array(6)].map((_, index) => (
                <input
                  key={index}
                  type="text"
                  maxLength="1"
                  value={verificationCode[index] || ''}
                  onChange={(e) => handleVerificationCodeChange(e, index)}
                  onKeyDown={(e) => {
                    if (e.key === 'Backspace' && !e.target.value && index > 0) {
                      const prevInput = e.target.previousElementSibling;
                      prevInput.focus();
                      prevInput.setSelectionRange(1, 1);
                    }
                  }}
                  onPaste={(e) => {
                    e.preventDefault();
                    const pastedData = e.clipboardData.getData('text/plain').replace(/\D/g, '').slice(0, 6);
                    const newCode = pastedData.padEnd(6, ' ');
                    setVerificationCode(newCode);
                    if (pastedData.length === 6) {
                      handleVerifyCode(pastedData);
                    } else if (pastedData.length > 0) {
                      document.getElementById(`code-${pastedData.length}`).focus();
                    }
                  }}
                  id={`code-${index}`}
                  className="w-12 h-12 text-center text-xl border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-black"
                />
              ))}
            </div>
            <button 
              onClick={() => handleVerifyCode(verificationCode)}
              className="mt-3 w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              Verify Code
            </button>
          </div>
        )}
        {error && <p className="mt-2 text-sm text-red-600">{error}</p>}
      </div>
    </div>
  );
};

export default Home;