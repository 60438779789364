// components/MyPoints.js
import React from 'react';

const MyPoints = () => {
  return (
    <div className="component-container">
      <h2 className="component-title">My Points</h2>
      <p className="component-text">View, gift, and redeem your points here.</p>
    </div>
  );
};

export default MyPoints;
